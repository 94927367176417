import TitleTextBlock from "../components/title-text-block";
import Slideshow from "../components/slideshow";
import { Col, Row } from "react-bootstrap";
import PhotoDownload from "../components/PhotoDownload";
import imageColoriage1 from '../public/images/coloriage/croatie1.jpg';
import imageColoriage2 from '../public/images/coloriage/croatie2.jpg';
import imageColoriage3 from '../public/images/coloriage/croatie3.jpg';
import imageColoriage4 from '../public/images/coloriage/lyon1.jpg';
import imageColoriage5 from '../public/images/coloriage/montmartre1.jpg';
import imageColoriage6 from '../public/images/coloriage/moulin1.jpg';
import imageColoriage7 from '../public/images/coloriage/paris1.jpg';
import imageColoriage8 from '../public/images/coloriage/suisse1.jpg';

import downloadFile1 from '../public/download/coloriages-pdf/croatie1.pdf'
import downloadFile2 from '../public/download/coloriages-pdf/croatie2.pdf'
import downloadFile3 from '../public/download/coloriages-pdf/croatie3.pdf'
import downloadFile4 from '../public/download/coloriages-pdf/lyon1.pdf'
import downloadFile5 from '../public/download/coloriages-pdf/montmartre-1.pdf'
import downloadFile6 from '../public/download/coloriages-pdf/moulin1.pdf'
import downloadFile7 from '../public/download/coloriages-pdf/paris1.pdf'
import downloadFile8 from '../public/download/coloriages-pdf/suisse1.pdf'

const GoodiesPage: React.FunctionComponent = () => {
    return (
        <>
            <section>
                <div className="w-75 m-auto">
                    <div className="pb-5 pt-5">
                        <TitleTextBlock title="goodies.title" text="goodies.short"/>
                    </div>
                    <Row>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage1} button="goodies.download" downloadFile={downloadFile1}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage2} button="goodies.download" downloadFile={downloadFile2}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage3} button="goodies.download" downloadFile={downloadFile3}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage4} button="goodies.download" downloadFile={downloadFile4}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage5} button="goodies.download" downloadFile={downloadFile5}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage6} button="goodies.download" downloadFile={downloadFile6}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage7} button="goodies.download" downloadFile={downloadFile7}/>
                        </Col>
                        <Col className="mt-2 mb-2" xs="12" md="6" lg="4" xl="3">
                            <PhotoDownload image={imageColoriage8} button="goodies.download" downloadFile={downloadFile8}/>
                        </Col>
                    </Row>
                </div>
            </section>

        </>
    )
  }

export default GoodiesPage;