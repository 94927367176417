import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import anastylose from '../public/images/Clients/client-anastylose.png'
import structural from '../public/images/Clients/client-structural.png'
import total from '../public/images/Clients/client-total.png'
import proBTP from '../public/images/Clients/client-ProBtp.png'
import berni from '../public/images/Clients/client-berni.png'

import avisBerni from '../public/images/Clients/Avis/avis-berni.png'
import avisMarion from '../public/images/Clients/Avis/avis-marion.png'

import { faStar} from "@fortawesome/free-solid-svg-icons"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const comments = [
    {
        title: "Simon - House of Berni",
        comment: "Je viens de terminer un projet d'illustration avec Lucie, tout s'est très bien passé. Elle est sérieuse, réactive, efficace, et comprend vite les choses. Je la recommande vraiment sans hésitation. Merci Lucie, à bientôt!",
        stars: 5,
        image: avisBerni
    },
    {
        title: "Marion - Particulier",
        comment: "Nous avons absolument été ravis du travail de Lucie. Elle a su transformer nos idées en de magnifiques illustrations pour le baptême de nos enfants et notre mariage. Les invitations et les livrets de messe étaient uniques et ont beaucoup plu à nos invités. Lucie est une artiste talentueuse et à l'écoute, et je la recommande les yeux fermés.",
        stars: 5,
        image: avisMarion
    }
];


function Clients() {
    const { t } = useTranslation();

    const renderComments = () => {
        return comments.map((comment, index) => (
            <Carousel.Item key={index}>
                <Container fluid>
                    <Row style={{alignContent: "center"}}>
                        <Col lg={9} className="order-lg-2 d-flex align-items-center">
                            <div className="h-100 align-content-center Centered-vertically">
                                <div className="d-flex flex-column flex-lg-row align-items-center w-100 gap-4">
                                    <h2 className="Title-secondary mb-0">{comment.title}</h2>
                                    <span className="ml-5">
                                        {Array.from({ length: comment.stars }).map((_, i) => (
                                            <FontAwesomeIcon key={i} className="Logo-list-secondary" icon={faStar} size="1x"/>
                                        ))}
                                    </span>
                                </div>
                                <p className="text-left mt-3 quote">{comment.comment}</p>
                                
                            </div>
                        </Col>
                        <Col lg={3} className="order-lg-1 d-flex align-items-center">
                            <Image fluid style={{height: "inherit"}} src={comment.image} rounded />
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
        ));
    };
    return (
        <>
            <h1 className="Title-primary">{t('customers.title')}</h1>
            <Container fluid>
                <Row  className="align-items-center">
                    <Col md={{span: 4, offset: 0}}  className="align-items-center">
                        <Image width="50%" src={structural} rounded />
                    </Col>
                    <Col md={4} className="align-items-center">
                        <Image width="50%" src={anastylose} rounded />
                    </Col>
                    <Col md={4} className="align-items-center">
                        <Image width="30%" src={total} rounded />
                    </Col>
                    <Col md={{span: 4, offset: 2}} className="align-items-center">
                        <Image width="50%" src={proBTP} rounded />
                    </Col>
                    <Col md={4} className="align-items-center">
                        <Image width="40%" src={berni} rounded />
                    </Col>
                </Row>
                <div style={{marginTop: "4rem"}}>
                                    <Carousel data-bs-theme="dark" className="myCarousel" controls={true} keyboard={true} touch={true}>
                                        {renderComments()}
                                    </Carousel>
                                </div>
            </Container>
        </>
    )
}

export default Clients;